import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { ENTERPRISE_CONTRACT_CANCEL_MODAL } from '.';
import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { CopyIdField } from '../../../components/CopyIdField';
import { locationForModal } from '../../../components/ModalRoute';

import { formatCredits } from '../../../utils';
import { ContractType } from './utils';

function PartnerContractListItem({
  contract,
  currency,
  location,
}: {
  contract: ContractType;
  currency: string;
  location;
}) {
  const startAt = moment.utc(contract.startAt);
  const expiresAt = moment.utc(contract.expiresAt);

  const status = (
    <span
      className={`text-white text-uppercase ${
        contract.status === 'current'
          ? 'bg-success'
          : contract.status === 'upcoming'
          ? 'bg-primary'
          : 'bg-danger'
      }`}
      style={{ padding: '0 8px', fontSize: 12 }}
    >
      {contract.status}
    </span>
  );

  return (
    <tr key={contract.id} style={{ whiteSpace: 'nowrap' }}>
      <td>{contract.type === 'MSA-SOW' ? 'MSA and SOW' : contract.type}</td>

      <td className="text-right">
        {formatCredits(contract.commitmentCreditsCents, { currency })}
      </td>

      <td className="text-right">{contract.discountPercentage}%</td>

      <td className="text-right">{contract?.subscriptionPlan?.displayName}</td>
      <td className="text-right">
        {contract?.subscriptionPlanDiscountPercentage ?? 0}%
      </td>

      <td className="text-right">
        {contract.commissionPercentage != null
          ? `${contract.commissionPercentage}%`
          : '-'}
      </td>

      <td>{startAt.format('DD MMM YY')}</td>

      <td>{expiresAt.format('DD MMM YY')}</td>

      <td>
        {contract.durationInMonths}{' '}
        {contract.durationInMonths == null
          ? ''
          : contract.durationInMonths > 1
          ? 'months'
          : contract.durationInMonths === 1
          ? 'month'
          : 'Less than one month'}
      </td>

      <td>{status}</td>

      <td>
        {(contract.remainingTime ?? 0) >= 1 &&
        (contract.remainingTime ?? 0) <= (contract.durationInMonths ?? 0)
          ? contract.remainingTime
          : '-'}{' '}
        {(contract.remainingTime ?? 0) > 1 &&
        (contract.remainingTime ?? 0) <= (contract.durationInMonths ?? 0)
          ? 'months'
          : (contract.remainingTime ?? 0) === 1
          ? 'month'
          : ''}
      </td>

      <td>
        <CopyIdField id={contract.id} />
      </td>

      <td className="text-right">
        <ActionsDropdown>
          {({ onClose }) => (
            <div style={{ textAlign: 'left', flexDirection: 'column' }}>
              <Link
                className="d-flex btn"
                to={locationForModal({
                  location,
                  modal: {
                    modalName: ENTERPRISE_CONTRACT_CANCEL_MODAL,
                    id: contract.id,
                    deactivatedAt:
                      contract.status !== 'deactivated'
                        ? new Date().toISOString()
                        : undefined,
                  },
                })}
              >
                {contract.status !== 'deactivated'
                  ? 'Deactivate contract'
                  : 'Activate contract'}
              </Link>
              <div style={{ paddingBottom: 8 }}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={contract.pdfUrl}
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    marginLeft: 12,
                  }}
                >
                  Download PDF
                </a>
              </div>
            </div>
          )}
        </ActionsDropdown>
      </td>
    </tr>
  );
}

export function PartnerContractList({
  location,
  contractList,
  currency,
}: {
  location;
  contractList: ContractType[];
  currency: string;
}) {
  return (
    <div className="table-responsive p-2">
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            <th className="text-muted text-truncate">Type</th>
            <th className="text-muted text-truncate text-right">
              Commitment in credits
            </th>
            <th className="text-muted text-truncate text-right">
              Credit discount
            </th>
            <th className="text-muted text-truncate text-right">
              Subscription plan
            </th>
            <th className="text-muted text-truncate text-right">
              Plan discount
            </th>
            <th className="text-muted text-truncate text-right">Commission</th>
            <th className="text-muted text-truncate">Executed</th>
            <th className="text-muted text-truncate">Expiry</th>
            <th className="text-muted text-truncate">Duration</th>
            <th className="text-muted text-truncate">Status</th>
            <th className="text-muted text-truncate">Remaining</th>
            <th className="text-muted text-truncate">Id</th>

            <th className="text-muted text-truncate" />
          </tr>
        </thead>

        <tbody>
          {(contractList ?? []).map((contract) => (
            <PartnerContractListItem
              key={contract.id}
              contract={contract}
              currency={currency}
              location={location}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
