import BaseLayout from '../components/BaseLayout';
import ContentWrapper from '../components/ContentWrapper';
import { MetabaseEmbed } from '../components/MetabaseEmbed';

export function AIFoodReportsRoute({ session, match }) {
  return (
    <BaseLayout session={session} match={match}>
      <h2>AI Food Reports</h2>

      <ContentWrapper>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <MetabaseEmbed report="ai_food_sla" />
          <MetabaseEmbed report="ai_food_main_queue_images_reviewed" />
        </div>
      </ContentWrapper>
    </BaseLayout>
  );
}
