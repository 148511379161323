import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import { Modal } from './Modal';

import { locationForModal } from './ModalRoute';
import { apiFetch } from '../utils';

type SelectedEditor = {
  label: string;
  value: string;
};

type EnterpriseRichTextSelectPartnerProps = {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: { token: string };
  onContinue: (context: { partnerId: string; guidelineId: string }) => void;
  onDismiss: () => void;
  modalParams: {
    partnerId: string;
    partnerName: string;
    guidelineId: string;
  };
};

export const EnterpriseRichTextSelectPartnerModal = ({
  location,
  history,
  session,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseRichTextSelectPartnerProps) => {
  const canSubmit = modalParams.partnerId != null;

  const [
    selectedPartner,
    setSelectedPartner,
  ] = React.useState<SelectedEditor | null>(
    modalParams.partnerId != null
      ? {
          label: modalParams.partnerName,
          value: modalParams.partnerId,
        }
      : null
  );

  async function onSubmitHandler(event) {
    event.preventDefault();

    if (canSubmit !== true) {
      return;
    }

    onContinue({
      partnerId: modalParams.partnerId,
      guidelineId: modalParams.guidelineId,
    });
  }

  const handleOnChange = (selectedValue: SelectedEditor) => {
    history.replace(
      locationForModal({
        location,
        modal: {
          ...modalParams,
          partnerName: selectedValue.label,
          partnerId: selectedValue.value,
        },
      })
    );
    setSelectedPartner(selectedValue);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Duplicate Guideline</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px' }}
          onSubmit={onSubmitHandler}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <p className="col mb-4">
                Select the enterprise you want the new guideline to belong to.
              </p>
            </div>
            <div className="row">
              <div className="form-group col">
                <label>Enterprise</label>
                <AsyncSelect
                  className="w-100"
                  loadOptions={async (inputValue) => {
                    try {
                      const response = await apiFetch<{
                        results: { uid: string; name: string }[];
                      }>(`/api/v2/partners/summary`, {
                        token: session.token,
                        query: { limit: 499, q: inputValue || undefined },
                      });

                      return response.results.map((p) => ({
                        label: p.name,
                        value: p.uid,
                      }));
                    } catch (e) {
                      alert(
                        'There was an error loading the partners, please try again'
                      );
                      return [];
                    }
                  }}
                  defaultOptions
                  value={selectedPartner}
                  onChange={handleOnChange}
                  isSearchable
                />
              </div>
            </div>
          </div>

          <div className="p-2" />

          <div className="flex-grow-1" />

          <div className="row">
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-secondary btn-block"
                onClick={onDismiss}
              >
                Cancel
              </button>
            </div>

            <div className="col">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={!canSubmit}
                onClick={() => {
                  onContinue({
                    partnerId: modalParams.partnerId,
                    guidelineId: modalParams.guidelineId,
                  });
                }}
              >
                Select
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
