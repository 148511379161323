import debounce from 'lodash.debounce';
import * as React from 'react';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import Component2 from '@reach/component-component';

import {
  apiFetch,
  checkIfSuperAdmin,
  convertToCents,
  formatMoneyCents,
} from '../utils';
import { Modal } from './Modal';
import { locationForModal } from './ModalRoute';

import pdfIcon from './img/pdf-icon.svg';
import { usePromise } from './usePromise';
import { gql, useLazyQuery, useMutation, useQuery } from './ApolloClient';
import { ENTERPRISE_ALLOW_CUSTOM_DISCOUNT_ENABLED } from '../config';

type EnterpriseInvoiceSetToPaidProps = {
  partnerId: string;
  session: { token: string };
  onDismiss: () => void;
  location;
  history;
  onContinue: () => void;
  modalParams: {
    modalName: string;
    id: string;
    invoiceId: string;
    invoiceCredits: string;
    pdfUrl: string;
    pdfFileName?: string;
    paymentDate?: string;
  };
};

export const EnterpriseInvoiceSetToPendingModal = ({
  partnerId,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseInvoiceSetToPaidProps) => {
  const [uploadProgress, setUploadProgress] = React.useState<
    number | undefined
  >(undefined);

  const promiserDropZone = usePromise();

  const [getInvoiceByIdAsAdmin, getInvoiceByIdAsAdminQuery] = useLazyQuery<
    {
      invoice: {
        id: string;
        invoiceId: string;
      };
    },
    { id?: string }
  >(
    gql`
      query GetInvoiceByIdAsAdminQuery($id: ID!) {
        invoice: partnerInvoiceByIdAsAdmin(invoiceId: $id) {
          id
          invoiceId
        }
      }
    `,
    {
      variables: { id: modalParams.id },
      onCompleted: (data) => {
        history.replace(
          locationForModal({
            location,
            modal: {
              ...modalParams,
              invoiceId: data.invoice.invoiceId,
            },
          })
        );
      },
    }
  );

  const [commitSetToPending, commitSetToPendingMutation] = useMutation(
    gql`
      mutation PartnerInvoiceSetToPendingAsAdminQuery(
        $id: ID!
        $paymentDate: String!
        $pdfUrl: String!
      ) {
        partnerInvoiceSetToPendingAsAdmin(
          input: { id: $id, paymentDate: $paymentDate, pdfUrl: $pdfUrl }
        ) {
          invoice {
            id
          }
        }
      }
    `
  );

  React.useEffect(() => {
    if (modalParams.id != null) {
      getInvoiceByIdAsAdmin();
    }
  }, [modalParams.id, getInvoiceByIdAsAdmin]);

  function setInvoiceToPaid() {
    const shouldContinue = window.confirm(
      `Are you sure you want to set this invoice(${modalParams.invoiceCredits} credits) to paid?`
    );

    if (shouldContinue) {
      commitSetToPending({
        variables: {
          id: modalParams.id,
          paymentDate: modalParams.paymentDate,
          pdfUrl: modalParams.pdfUrl,
        },
      }).then(() => onContinue());
    }
  }

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">
            Submit invoice for approval ({modalParams.invoiceCredits} credits)
          </h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '600px' }}
          onSubmit={(e) => {
            e.preventDefault();
            setInvoiceToPaid();
          }}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <div className="form-group col">
                <label>
                  <p className="text-secondary">Invoice ID</p>
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={modalParams.invoiceId}
                  readOnly
                />
              </div>

              <div className="form-group col">
                <label>
                  <p className="text-secondary">Date of payment</p>
                </label>

                <input
                  type="date"
                  className="form-control"
                  value={modalParams.paymentDate}
                  disabled={commitSetToPendingMutation.loading}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          paymentDate: value,
                        },
                      })
                    )
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12">
                <label>
                  <p className="text-secondary">Proof of payment:</p>
                </label>

                {modalParams.pdfFileName != null &&
                modalParams.pdfUrl != null ? (
                  <div
                    className="form-control"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#F6F9FF',
                      border: '1px solid #C1C8D4',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="d-flex text-center">
                      <img
                        src={pdfIcon}
                        alt="pdf"
                        style={{
                          width: 35,
                        }}
                      />

                      <div className="d-flex" style={{ width: 250 }}>
                        <span
                          style={{
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            paddingRight: '0.5em',
                          }}
                        >
                          {modalParams.pdfFileName}
                        </span>
                      </div>
                    </div>

                    <button
                      style={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        paddingTop: 4,
                      }}
                      onClick={(event) => {
                        setUploadProgress(undefined);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              pdfUrl: undefined,
                              pdfFileName: undefined,
                            },
                          })
                        );
                      }}
                      className="d-flex text-center align-center"
                    >
                      <i
                        className="fa fa-times text-muted fa-lg"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                ) : (
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    multiple={false}
                    style={{
                      border: '1px dashed #C1C8D4',
                      borderRadius: '4px',
                      height: 160,
                    }}
                    disabled={
                      commitSetToPendingMutation.loading ||
                      promiserDropZone.isPending
                    }
                    onDrop={(file) => {
                      const axiosCancelSource = axios.CancelToken.source();
                      promiserDropZone.setPromise(
                        apiFetch(`/api/v2/media/presigned-post-public-asset`, {
                          token: session.token,
                          method: 'POST',
                          body: JSON.stringify({
                            fileType: file[0].type,
                            fileName: file[0].name,
                          }),
                        })
                          .then(
                            ({ signedUrl, key }) =>
                              file != null &&
                              axios
                                .put(signedUrl, file[0], {
                                  onUploadProgress: (progressEvent) => {
                                    setUploadProgress(
                                      Math.round(
                                        (progressEvent.loaded * 100) /
                                          progressEvent.total
                                      )
                                    );
                                  },
                                  cancelToken: axiosCancelSource.token,
                                  headers: { 'Content-Type': file[0].type },
                                })
                                .then(() =>
                                  apiFetch(
                                    `/api/v2/media/get-public-asset-url/${key}`,
                                    {
                                      token: session.token,
                                    }
                                  )
                                )
                          )
                          .then(({ url }) => {
                            history.replace(
                              locationForModal({
                                location,
                                modal: {
                                  ...modalParams,
                                  pdfUrl: url,
                                  pdfFileName: file[0].name,
                                },
                              })
                            );
                          })
                      );
                    }}
                  >
                    <div className="card-body text-center">
                      {uploadProgress == null ? (
                        <p>
                          Drag and drop the image or PDF with the proof of
                          payment here <br />
                          <span style={{ textDecoration: 'underline' }}>
                            or browse
                          </span>
                        </p>
                      ) : (
                        <p className="text-secondary">
                          Progress: {uploadProgress}%
                        </p>
                      )}
                    </div>
                  </Dropzone>
                )}
              </div>
            </div>
          </div>
          <div className="p-2"></div>

          <div className="d-flex justify-content-between mt-3">
            <button
              type="submit"
              className="btn btn-block btn-dark"
              disabled={
                modalParams.pdfUrl == null ||
                modalParams.paymentDate == null ||
                commitSetToPendingMutation.loading
              }
            >
              Submit payment for approval
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

type EnterpriseInvoiceCreateProps = {
  partnerId: string;
  currency: string;
  session: { token: string };
  onDismiss: () => void;
  location;
  history;
  onContinue: () => void;
  modalParams: {
    modalName: string;
    id: string;
  };
};

export const EnterpriseInvoiceCreateModal = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseInvoiceCreateProps) => {
  const [sendTo, setSendTo] = React.useState<string>();
  const [credits, setCredits] = React.useState<string>();
  const [
    otherLineItemDescription,
    setOtherLineItemDescription,
  ] = React.useState<string>();
  const [
    otherLineItemAmount,
    setOtherLineItemAmount,
  ] = React.useState<string>();
  const [paymentTerms, setPaymentTerms] = React.useState<string>();

  const [discountValidationError, setDiscountValidationError] = React.useState<
    string | null
  >(null);

  const getPaymentTermsQuery = useQuery<{
    paymentTerms: { description: string; value: string }[];
  }>(gql`
    query GetPartnerInvoicePaymentTermOptionsForInvoiceCreation {
      paymentTerms: paymentTermsForInvoiceCreation {
        description
        value
      }
    }
  `);

  const [
    validatePartnerInvoiceCreationAsAdmin,
    {
      data: validationData,
      loading: validationLoading,
      error: validationError,
    },
  ] = useMutation<
    {
      form?: {
        invoiceId: string;
        discountPercentage?: number;
        lineItemAmount?: number;
        totalInvoiceAmount?: number;

        token: string;
      };
    },
    {
      partnerId: string;
      sendTo?: string;
      credits?: number;
      otherLineItemDescription?: string;
      otherLineItemAmount?: number;
      paymentTerms?: string;
      discountOverride?: number;
    }
  >(
    gql`
      mutation NewPartnerInvoiceCreationValidateAsAdmin(
        $partnerId: ID!
        $sendTo: String
        $credits: Int
        $otherLineItemDescription: String
        $otherLineItemAmount: Int
        $paymentTerms: String
        $discountOverride: Float
      ) {
        form: partnerInvoiceCreationValidateAsAdmin(
          input: {
            partnerId: $partnerId
            sendTo: $sendTo
            credits: $credits
            otherLineItemDescription: $otherLineItemDescription
            otherLineItemAmount: $otherLineItemAmount
            paymentTerms: $paymentTerms
            discountOverride: $discountOverride
          }
        ) {
          invoiceId
          discountPercentage
          lineItemAmount
          totalInvoiceAmount

          token
        }
      }
    `
  );

  const [discount, setDiscount] = React.useState<number | undefined>(
    validationData?.form?.discountPercentage
  );

  const isSuperAdmin = checkIfSuperAdmin({ session });

  const customDiscountIsAllowed =
    ENTERPRISE_ALLOW_CUSTOM_DISCOUNT_ENABLED === true && isSuperAdmin === true;

  const [
    commitPartnerInvoiceCreationAsAdmin,
    commitPartnerInvoiceCreationAsAdminMutation,
  ] = useMutation<
    { data?: { invoice?: { id: string } } },
    {
      partnerId: string;
      sendTo: string;
      credits: number;
      otherLineItemDescription?: string;
      otherLineItemAmount?: number;
      paymentTerms: string;
      token: string;
      discountOverride?: number;
    }
  >(
    gql`
      mutation NewPartnerInvoiceCreationCommitAsAdmin(
        $partnerId: ID!
        $sendTo: String!
        $credits: Int!
        $otherLineItemDescription: String
        $otherLineItemAmount: Int
        $paymentTerms: String!
        $token: String!
        $discountOverride: Float
      ) {
        partnerInvoiceCreationCommitAsAdmin(
          input: {
            partnerId: $partnerId
            sendTo: $sendTo
            credits: $credits
            otherLineItemDescription: $otherLineItemDescription
            otherLineItemAmount: $otherLineItemAmount
            paymentTerms: $paymentTerms
            token: $token
            discountOverride: $discountOverride
          }
        ) {
          invoice {
            id
          }
        }
      }
    `
  );

  const invoiceValidationProps = {
    validatePartnerInvoiceCreationAsAdmin,
    partnerId,
    sendTo,
    credits,
    otherLineItemDescription,
    otherLineItemAmount,
    paymentTerms,
    discountOverride: customDiscountIsAllowed ? discount : undefined,
  };

  function validateInvoiceCreation({
    validatePartnerInvoiceCreationAsAdmin,
    partnerId,
    sendTo,
    credits,
    otherLineItemDescription,
    otherLineItemAmount,
    paymentTerms,
    discountOverride,
  }: any) {
    validatePartnerInvoiceCreationAsAdmin({
      variables: {
        partnerId,
        sendTo,
        credits: convertToCents(parseInt(credits ?? '')),
        otherLineItemDescription,
        otherLineItemAmount: convertToCents(
          parseFloat(otherLineItemAmount ?? '')
        ),
        paymentTerms,
        discountOverride,
      },
    });
  }

  const validateInvoiceCreationDebounced = React.useCallback(
    debounce(validateInvoiceCreation, 300),
    []
  );

  React.useEffect(
    () => validateInvoiceCreationDebounced(invoiceValidationProps),
    [
      validatePartnerInvoiceCreationAsAdmin,
      partnerId,
      sendTo,
      credits,
      otherLineItemDescription,
      otherLineItemAmount,
      paymentTerms,
      discount,
    ]
  );

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();

    await commitPartnerInvoiceCreationAsAdmin({
      variables: {
        partnerId,
        sendTo: sendTo ?? '',
        credits: convertToCents(parseInt(credits ?? '')) as number,
        otherLineItemDescription,
        otherLineItemAmount: convertToCents(
          parseFloat(otherLineItemAmount ?? '')
        ),
        paymentTerms: paymentTerms ?? '',
        token: validationData?.form?.token ?? '',
        discountOverride: customDiscountIsAllowed ? discount : undefined,
      },
    });
    onContinue();
  }

  const isFormValid = sendTo != null && credits != null && paymentTerms != null;

  return (
    <Modal onDismiss={onDismiss}>
      <Component2
        didMount={() =>
          validateInvoiceCreationDebounced(invoiceValidationProps)
        }
      />
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Create invoice</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px' }}
          onSubmit={onSubmit}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <div className="form-group col-6">
                <label className="text-secondary">Invoice ID</label>

                <input
                  type="text"
                  className="form-control"
                  value={validationData?.form?.invoiceId ?? ''}
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label className="text-secondary">Send to</label>

                <input
                  type="text"
                  className="form-control"
                  value={sendTo ?? ''}
                  disabled={validationError != null}
                  onChange={(e) => setSendTo(e.target.value)}
                />
              </div>

              <div className="form-group col">
                <label className="text-secondary">Number of credits</label>

                <input
                  type="number"
                  className="form-control"
                  placeholder="e.g. 10,000"
                  value={credits ?? ''}
                  disabled={validationError != null}
                  onChange={(e) => setCredits(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label className="text-secondary">
                  Discount on credits (%)
                </label>

                <input
                  type="number"
                  min="0"
                  max="100"
                  className="form-control"
                  value={discount ?? validationData?.form?.discountPercentage}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value < 0 || value > 100) {
                      setDiscountValidationError(
                        'Discount must be between 0 and 100'
                      );
                    } else {
                      setDiscountValidationError(null);
                      setDiscount(parseFloat(e.target.value));
                    }
                  }}
                  readOnly={!customDiscountIsAllowed}
                />
                {discountValidationError && (
                  <small className="text-danger">
                    {discountValidationError}
                  </small>
                )}
              </div>

              <div className="form-group col">
                <label className="text-secondary">
                  Credits line-item amount ({currency})
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={
                    validationData?.form?.lineItemAmount != null
                      ? formatMoneyCents(validationData.form.lineItemAmount, {
                          currency,
                        })
                      : ''
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label className="text-secondary">
                  Other line-item description (Optional)
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={otherLineItemDescription ?? ''}
                  disabled={validationError != null}
                  onChange={(e) => setOtherLineItemDescription(e.target.value)}
                />
              </div>

              <div className="form-group col">
                <label className="text-secondary">
                  Other line-item amount ({currency}) (Optional)
                </label>

                <input
                  type="text"
                  className="form-control"
                  placeholder="e.g. 1,000"
                  value={otherLineItemAmount ?? ''}
                  disabled={validationError != null}
                  onChange={(e) => setOtherLineItemAmount(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label className="text-secondary">
                  Final total invoice amount ({currency})
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={
                    validationData?.form?.totalInvoiceAmount != null
                      ? formatMoneyCents(
                          validationData.form.totalInvoiceAmount,
                          { currency }
                        )
                      : ''
                  }
                  readOnly
                />
              </div>

              <div className="form-group col">
                <label className="text-secondary">Payment terms</label>

                <select
                  className="form-control"
                  value={paymentTerms ?? ''}
                  disabled={validationError != null}
                  onChange={(e) => {
                    setPaymentTerms(e.target.value);
                    setTimeout(validateInvoiceCreationDebounced.flush);
                  }}
                >
                  <option value="">Select</option>
                  {(getPaymentTermsQuery.data?.paymentTerms ?? []).map(
                    (option) => (
                      <option key={option.value} value={option.value}>
                        {option.description}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className="mt-3">
            {validationError != null && (
              <p className="text-danger w-100 mb-2">
                {validationError?.graphQLErrors[0].message ??
                  'An unexpected error has ocurred'}
              </p>
            )}

            {commitPartnerInvoiceCreationAsAdminMutation.error != null && (
              <p className="text-danger w-100 mb-2">
                {commitPartnerInvoiceCreationAsAdminMutation.error.message}
              </p>
            )}

            <button
              type="submit"
              className="btn btn-block btn-dark"
              disabled={
                isFormValid === false ||
                validationLoading === true ||
                validationError != null ||
                commitPartnerInvoiceCreationAsAdminMutation.loading
              }
            >
              Create invoice
              {validationLoading === true && validationData == null && '...'}
            </button>
          </div>
        </form>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};

type EnterpriseInvoiceCancelProps = {
  partnerId: string;
  session: { token: string };
  onDismiss: () => void;
  location;
  history;
  onContinue: () => void;
  modalParams: {
    modalName: string;
    id: string;
  };
};

export const EnterpriseInvoiceCancelModal = ({
  partnerId,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseInvoiceCancelProps) => {
  const [commitSetToCancelled, commitSetToCancelledMutation] = useMutation(
    gql`
      mutation PartnerInvoiceSetToCancedlledAsAdminMutation($id: ID!) {
        partnerInvoiceSetToCancelledAsAdmin(input: { id: $id }) {
          invoice {
            id
          }
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Cancel invoice</h4>
        </div>
        <div className="card-body d-flex flex-column">
          <div style={{ padding: 16, textAlign: 'center' }}>
            Are you sure you want to cancel this unpaid invoice?
          </div>
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}
          >
            <button
              disabled={commitSetToCancelledMutation.loading}
              onClick={() =>
                commitSetToCancelled({
                  variables: {
                    id: modalParams.id,
                  },
                }).then(() => onContinue())
              }
              type="submit"
              className="btn  btn-danger"
            >
              Yes
            </button>
            <button
              type="submit"
              className="btn  btn-dark"
              disabled={commitSetToCancelledMutation.loading}
              onClick={() => onDismiss()}
            >
              No
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};

export const EnterpriseInvoiceSetToPaidModal = ({
  partnerId,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseInvoiceCancelProps) => {
  const [commitSetToPaid, commitSetToPaidMutation] = useMutation(
    gql`
      mutation PartnerInvoiceSetToPaidAsAdminMutation($id: ID!) {
        partnerInvoiceSetToPaidAsAdmin(input: { id: $id }) {
          invoice {
            id
          }
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">Approve invoice payment</h4>
        </div>
        <div className="card-body d-flex flex-column">
          <div style={{ padding: 16, textAlign: 'center' }}>
            Are you sure you want to set this invoice as paid?
          </div>
          <div
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}
          >
            <button
              disabled={commitSetToPaidMutation.loading}
              onClick={() =>
                commitSetToPaid({
                  variables: {
                    id: modalParams.id,
                  },
                }).then(() => onContinue())
              }
              type="submit"
              className="btn  btn-danger"
            >
              Yes
            </button>
            <button
              type="submit"
              className="btn  btn-dark"
              disabled={commitSetToPaidMutation.loading}
              onClick={() => onDismiss()}
            >
              No
            </button>
          </div>
        </div>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};
