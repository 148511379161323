import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import moment from 'moment-timezone';
import isFinite from 'lodash/isFinite';
import { gql, useQuery } from './ApolloClient';

import { apiFetch, formatMoneyCents } from '../utils';
import { Modal } from './Modal';

import { usePromise } from './usePromise';
import { locationForModal } from './ModalRoute';

import { COLOR_PALLETTE } from '../constants';

import pdfIcon from './img/pdf-icon.svg';
import closeIcon from './img/close-icon.svg';
import chevronRight from '../img/chevron-right.svg';

const CONTRACT_DURATION = [
  { value: 'MONTHS_1', text: '1 month' },
  { value: 'MONTHS_2', text: '2 months' },
  { value: 'MONTHS_3', text: '3 months' },
  { value: 'MONTHS_12', text: '12 months' },
  { value: 'MONTHS_24', text: '24 months' },
];

type EnterpriseContractProps = {
  partnerId: string;
  currency: string;
  session: { token: string };
  onDismiss: () => void;
  onContinue: () => void;
  location;
  history;
  modalParams: {
    modalName: string;
    type?: string;
    commitmentCreditsCents?: string;
    discountPercentage?: string;
    startAt?: string;
    durationInMonths?: string;
    referralCommissionsPercentage?: string;
    subscriptionPlan?: string;
    subscriptionPlanDiscountPercentage?: string;
    pdfUrl?: string;
    pdfFileName?: string;
    contractType?: string;
  };
};

type FormValues = {
  type: string;
  commitmentCreditsCents: number | '';
  discountPercentage: number | '';
  commitmentDollars: number | '';
  startAt: string;
  durationInMonths: string;
  referralCommissionsPercentage: number | '';
  subscriptionPlan: string;
  subscriptionPlanDiscountPercentage: number | '';
  pdfUrl: string;
  pdfFileName: string;
};

function validate(values) {
  const errors: {
    type?: string;
    commitmentCreditsCents?: string;
    startAt?: string;
    durationInMonths?: string;
    discountPercentage?: string;
    referralCommissionsPercentage?: string;
    subscriptionPlan?: string;
    subscriptionPlanDiscountPercentage?: string;
    pdfUrl?: string;
  } = {};

  if (values.type == null || values.type === '') {
    errors.type = 'Required';
  } else if (['MSA_SOW', 'SOW'].includes(values.type) === false) {
    errors.type = 'Invalid contract type';
  }

  if (
    values.commitmentCreditsCents == null ||
    values.commitmentCreditsCents === ''
  ) {
    errors.commitmentCreditsCents = 'Required';
  } else if (isFinite(values.commitmentCreditsCents) === false) {
    errors.commitmentCreditsCents = 'Must be a valid number';
  }

  if (values.discountPercentage == null || values.discountPercentage === '') {
    errors.discountPercentage = 'Required';
  } else if (isFinite(values.discountPercentage) === false) {
    errors.discountPercentage = 'Must be a valid number';
  } else if (values.discountPercentage < 0 || values.discountPercentage > 100) {
    errors.discountPercentage = 'Must be between 0 and 100';
  }

  if (values.startAt == null || values.startAt === '') {
    errors.startAt = 'Required';
  } else if (moment(values.startAt).isValid() === false) {
    errors.startAt = 'Invalid date';
  }

  const validTermValues = CONTRACT_DURATION.map((durationInMonths) =>
    durationInMonths.value.toString()
  );
  if (values.durationInMonths == null || values.durationInMonths === '') {
    errors.durationInMonths = 'Required';
  } else if (validTermValues.includes(values.durationInMonths) === false) {
    errors.durationInMonths = 'Invalid durationInMonths';
  }

  if (
    values.referralCommissionsPercentage !== '' &&
    isFinite(values.referralCommissionsPercentage) === false
  ) {
    errors.referralCommissionsPercentage = 'Must be a valid number';
  } else if (
    values.referralCommissionsPercentage < 0 ||
    values.referralCommissionsPercentage > 100
  ) {
    errors.referralCommissionsPercentage = 'Must be between 0 and 100';
  }

  if (values.subscriptionPlan == null || values.subscriptionPlan === '') {
    errors.subscriptionPlan = 'Required';
  }

  if (
    values.subscriptionPlanDiscountPercentage == null ||
    values.subscriptionPlanDiscountPercentage === ''
  ) {
    errors.subscriptionPlanDiscountPercentage = 'Required';
  } else if (isFinite(values.subscriptionPlanDiscountPercentage) === false) {
    errors.subscriptionPlanDiscountPercentage = 'Must be a valid number';
  } else if (
    values.subscriptionPlanDiscountPercentage < 0 ||
    values.subscriptionPlanDiscountPercentage > 100
  ) {
    errors.subscriptionPlanDiscountPercentage = 'Must be between 0 and 100';
  }

  if (values.pdfUrl == null || values.pdfUrl === '') {
    errors.pdfUrl = 'Required';
  }

  return errors;
}

type DiscountFormValues = {
  commitmentInUSD: number | '';
};

function validateValuesProductBasedDiscountModal(values) {
  const errors: {
    type?: string;
    commitmentInUSD?: string;
    startAt?: string;
    durationInMonths?: string;
    subscriptionPlan?: string;
    referralCommissionsPercentage?: string;
  } = {};

  if (values.type == null || values.type === '') {
    errors.type = 'Required';
  } else if (['MSA_SOW', 'SOW'].includes(values.type) === false) {
    errors.type = 'Invalid contract type';
  }

  if (values.commitmentInUSD == null || values.commitmentInUSD === '') {
    errors.commitmentInUSD = 'Required';
  } else if (isFinite(values.commitmentInUSD) === false) {
    errors.commitmentInUSD = 'Must be a valid number';
  } else if (values.commitmentInUSD < 0) {
    errors.commitmentInUSD = 'Must be equal or greater than 0';
  }

  if (values.startAt == null || values.startAt === '') {
    errors.startAt = 'Required';
  } else if (moment(values.startAt).isValid() === false) {
    errors.startAt = 'Invalid date';
  }

  const validTermValues = CONTRACT_DURATION.map((durationInMonths) =>
    durationInMonths.value.toString()
  );
  if (values.durationInMonths == null || values.durationInMonths === '') {
    errors.durationInMonths = 'Required';
  } else if (validTermValues.includes(values.durationInMonths) === false) {
    errors.durationInMonths = 'Invalid durationInMonths';
  }

  if (values.subscriptionPlan == null || values.subscriptionPlan === '') {
    errors.subscriptionPlan = 'Required';
  }

  if (
    values.referralCommissionsPercentage !== '' &&
    isFinite(values.referralCommissionsPercentage) === false
  ) {
    errors.referralCommissionsPercentage = 'Must be a valid number';
  } else if (
    values.referralCommissionsPercentage < 0 ||
    values.referralCommissionsPercentage > 100
  ) {
    errors.referralCommissionsPercentage = 'Must be between 0 and 100';
  }
  return errors;
}

function validateDiscountValues(values) {
  const errors: {
    capture?: string;
    aiMagic?: string;
    editingJobs?: string;
    planDiscount?: string;
  } = {};

  if (values.capture === '' || values.capture === null) {
    errors.capture = 'Must select a value';
    if (values.capture !== '' && isFinite(parseInt(values.capture)) === false) {
      errors.capture = 'Must be a valid number';
    } else if (values.capture < 0 || values.capture > 100) {
      errors.capture = 'Must be between 0 and 100';
    }
  }

  if (values.aiMagic === '' || values.aiMagic === null) {
    errors.aiMagic = 'Must select a value';
    if (values.aiMagic !== '' && isFinite(parseInt(values.aiMagic)) === false) {
      errors.aiMagic = 'Must be a valid number';
    } else if (values.aiMagic < 0 || values.aiMagic > 100) {
      errors.aiMagic = 'Must be between 0 and 100';
    }
  }

  if (values.editingJobs === '' || values.editingJobs === null) {
    errors.editingJobs = 'Must select a value';
    if (
      values.editingJobs !== '' &&
      isFinite(parseInt(values.editingJobs)) === false
    ) {
      errors.editingJobs = 'Must be a valid number';
    } else if (values.editingJobs < 0 || values.editingJobs > 100) {
      errors.editingJobs = 'Must be between 0 and 100';
    }
  }

  if (values.planDiscount === '' || values.planDiscount === null) {
    errors.planDiscount = 'Must select a value';
    if (
      values.planDiscount !== '' &&
      isFinite(parseInt(values.planDiscount)) === false
    ) {
      errors.planDiscount = 'Must be a valid number';
    } else if (values.planDiscount < 0 || values.planDiscount > 100) {
      errors.planDiscount = 'Must be between 0 and 100';
    }
  }

  return errors;
}

enum contractTypeEnum {
  PRODUCT_BASED_DISCOUNT = 'PRODUCT_BASED_DISCOUNT',
  CREDIT_BASED_DISCOUNT = 'CREDIT_BASED_DISCOUNT',
}

const contractTypeOptions = [
  {
    value: contractTypeEnum.PRODUCT_BASED_DISCOUNT,
    label: 'Product based discount',
    isRecommended: true,
  },
  {
    value: contractTypeEnum.CREDIT_BASED_DISCOUNT,
    label: 'Credit based discount',
    isRecommended: false,
  },
];

function Card({
  label,
  value,
  badgeColor,
  badgeLabel,
  onClick,
}: {
  label: string;
  value: string;
  badgeColor: string;
  badgeLabel: string;
  onClick: (value: string) => void;
}) {
  return (
    <button
      className=" tw-bg-white tw-rounded-sm tw-border-solid tw-border-[1px] tw-border-snappr-gray-3 
    tw-flex tw-p-6 tw-items-center tw-text-snappr-gray-5 tw-text-lg tw-justify-between"
      onClick={() => onClick(value)}
    >
      <span className="tw-text-lg tw-font-bold">{label}</span>

      <div className="tw-flex tw-items-center tw-gap-6">
        <span
          className={`badge tw-text-[10px] tw-font-bold tw-text-white tw-px-1 tw-rounded-[1px]`}
          style={{ backgroundColor: badgeColor }}
        >
          {badgeLabel}
        </span>

        <div>
          <img alt="select" src={chevronRight} />
        </div>
      </div>
    </button>
  );
}

export const ContractDiscountTypeSelectionModal = ({
  history,
  location,
  onDismiss,
  onContinue,
  modalParams,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onDismiss: () => void;
  onContinue: (props: { nextStep: 'product-based' | 'credit-based' }) => void;
  modalParams: { any };
}) => {
  return (
    <Modal onDismiss={onDismiss}>
      <div className="card tw-p-8 tw-bg-white tw-min-w-[700px] tw-gap-6">
        <div className="tw-flex tw-justify-between tw-items-center">
          <h4 className="tw-text-snappr-gray-5 tw-mb-0">
            Select a type of contract
          </h4>
          <button
            className="tw-bg-transparent tw-border-none"
            onClick={onDismiss}
          >
            <img alt="close" src={closeIcon} />
          </button>
        </div>

        <div className="tw-flex tw-flex-col tw-gap-3">
          {contractTypeOptions.map((option) => (
            <Card
              key={option.value}
              label={option.label}
              value={option.value}
              badgeColor={
                option.isRecommended === true
                  ? COLOR_PALLETTE.SUCCESS
                  : COLOR_PALLETTE.DANGER
              }
              badgeLabel={
                option.isRecommended === true ? 'RECOMMENDED' : 'LEGACY '
              }
              onClick={(value) => {
                onContinue({
                  nextStep:
                    value === contractTypeEnum.PRODUCT_BASED_DISCOUNT
                      ? 'product-based'
                      : 'credit-based',
                });
              }}
            />
          ))}
        </div>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};

export const EnterpriseContractModalFirst = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseContractProps) => {
  const [uploadProgress, setUploadProgress] = React.useState<
    number | undefined
  >(undefined);

  const promiserDropZone = usePromise();

  function onSubmit(values) {
    onContinue();
  }

  const partnerSubscriptionsQuery = useQuery<{
    partnerById: {
      availableSubscriptions: {
        edges: {
          id: string;
          slug: string;
          description: string;
          displayName: string;
          tier: string;
          price: number;
        }[];
      };
    };
  }>(
    gql`
      query partnerSubscriptionsQuery($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          availableSubscriptions {
            edges {
              id
              slug
              description
              displayName
              tier
              price
            }
          }
        }
      }
    `,
    {
      variables: {
        partnerId,
      },
      skip: partnerId == null,
    }
  );

  const subscriptionPlans =
    partnerSubscriptionsQuery?.data?.partnerById?.availableSubscriptions?.edges;

  const sortedPlans =
    subscriptionPlans != null
      ? [...subscriptionPlans]?.sort((a, b) => (a.price > b.price ? -1 : 1))
      : null;

  const commitmentDollars =
    modalParams.commitmentCreditsCents != null &&
    modalParams.discountPercentage != null
      ? parseInt(modalParams.commitmentCreditsCents) /
        parseFloat(modalParams.discountPercentage)
      : '';

  const initialValues: FormValues = {
    type: modalParams.type ?? '',
    commitmentCreditsCents:
      modalParams.commitmentCreditsCents != null
        ? parseInt(modalParams.commitmentCreditsCents)
        : '',
    startAt: modalParams.startAt ?? '',
    durationInMonths: modalParams.durationInMonths ?? '',
    discountPercentage:
      modalParams.discountPercentage != null
        ? parseFloat(modalParams.discountPercentage)
        : '',
    referralCommissionsPercentage:
      modalParams.referralCommissionsPercentage != null
        ? parseFloat(modalParams.referralCommissionsPercentage)
        : '',

    subscriptionPlanDiscountPercentage:
      modalParams.subscriptionPlanDiscountPercentage != null
        ? parseFloat(modalParams.subscriptionPlanDiscountPercentage)
        : '',
    subscriptionPlan: modalParams.subscriptionPlan ?? '',
    pdfUrl: modalParams.pdfUrl ?? '',
    pdfFileName: modalParams.pdfFileName ?? '',
    commitmentDollars,
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <h4 className="mb-0 ml-4 mt-4">Create a contract</h4>

        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({
            values,
            touched,
            errors,
            isValid,
            handleChange,
            setFieldValue,
          }) => (
            <Form
              className="card-body d-flex flex-column"
              style={{ width: '1000px' }}
            >
              <div className="flex-1" style={{ position: 'relative' }}>
                <div className="row">
                  <div className="form-group col-12">
                    <label
                      className={
                        touched.type && errors.type
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Type
                    </label>

                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-MSA-SOW"
                        className="form-check-input"
                        value="MSA_SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="type-MSA-SOW"
                      >
                        Master Service Agreement (MSA) and Statement of Work
                        (SOW)
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-SOW"
                        className="form-check-input"
                        value="SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label className="form-check-label" htmlFor="type-SOW">
                        Statement of Work (SOW)
                      </label>
                    </div>

                    <ErrorMessage name="type">
                      {(msg) => (
                        <p className="form-group mb-0 text-danger">{msg}</p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.commitmentCreditsCents &&
                        errors.commitmentCreditsCents
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Commitment in credits
                    </label>

                    <Field
                      name="commitmentCreditsCents"
                      type="number"
                      className={`form-control ${
                        touched.commitmentCreditsCents &&
                        errors.commitmentCreditsCents
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 100,000"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              commitmentCreditsCents: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="commitmentCreditsCents">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.discountPercentage && errors.discountPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Credit discount (%)
                    </label>

                    <Field
                      name="discountPercentage"
                      type="number"
                      className={`form-control ${
                        touched.discountPercentage && errors.discountPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              discountPercentage: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="discountPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.startAt && errors.startAt
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Execution date
                    </label>

                    <Field
                      name="startAt"
                      type="date"
                      className={`form-control ${
                        touched.startAt && errors.startAt ? 'border-danger' : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              startAt: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="startAt">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.durationInMonths && errors.durationInMonths
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Term
                    </label>

                    <Field
                      name="durationInMonths"
                      as="select"
                      className={`form-control ${
                        touched.durationInMonths && errors.durationInMonths
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              durationInMonths: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a term
                      </option>
                      {CONTRACT_DURATION.map((duration) => (
                        <option key={duration.value} value={duration.value}>
                          {duration.text}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="durationInMonths">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Plan
                    </label>

                    <Field
                      name="subscriptionPlan"
                      as="select"
                      className={`form-control ${
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              subscriptionPlan: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a plan
                      </option>
                      {sortedPlans?.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.displayName} -{' '}
                          {formatMoneyCents(plan.price, { currency })}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="subscriptionPlan">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.subscriptionPlanDiscountPercentage &&
                        errors.subscriptionPlanDiscountPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Plan discount
                    </label>

                    <Field
                      name="subscriptionPlanDiscountPercentage"
                      type="number"
                      className={`form-control ${
                        touched.subscriptionPlanDiscountPercentage &&
                        errors.subscriptionPlanDiscountPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              subscriptionPlanDiscountPercentage:
                                e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="subscriptionPlanDiscountPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Referral commissions (%) (Optional)
                    </label>

                    <Field
                      name="referralCommissionsPercentage"
                      type="number"
                      className={`form-control ${
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              referralCommissionsPercentage: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="referralCommissionsPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col-6">
                    <label className="text-secondary">
                      Commitment in {currency}
                    </label>

                    <Field
                      name="commitmentDollars"
                      type="text"
                      className="form-control"
                      value={
                        isFinite(values.commitmentCreditsCents) === true &&
                        isFinite(values.discountPercentage) === true
                          ? parseInt(values.commitmentCreditsCents) *
                            (1 - parseFloat(values.discountPercentage) / 100)
                          : undefined
                      }
                      placeholder="e.g. 90,000.00 USD"
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label className="text-secondary">PDF:</label>

                    {modalParams.pdfFileName != null &&
                    modalParams.pdfUrl != null ? (
                      <div
                        className="form-control"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: '#F6F9FF',
                          border: '1px solid #C1C8D4',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="d-flex text-center">
                          <img
                            src={pdfIcon}
                            alt="pdf"
                            style={{
                              width: 35,
                            }}
                          />

                          <div className="d-flex" style={{ width: 250 }}>
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingRight: '0.5em',
                              }}
                            >
                              {modalParams.pdfFileName}
                            </span>
                          </div>
                        </div>

                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            paddingTop: 4,
                          }}
                          onClick={(event) => {
                            setUploadProgress(undefined);
                            history.replace(
                              locationForModal({
                                location,
                                modal: {
                                  ...modalParams,
                                  pdfUrl: undefined,
                                  pdfFileName: undefined,
                                },
                              })
                            );
                          }}
                          className="d-flex text-center align-center"
                        >
                          <i
                            className="fa fa-times text-muted fa-lg"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : (
                      <Dropzone
                        accept="application/pdf"
                        style={{
                          border: '1px dashed #C1C8D4',
                          borderRadius: '4px',
                          height: 160,
                        }}
                        multiple={false}
                        disabled={promiserDropZone.isPending}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length === 0) return;

                          const axiosCancelSource = axios.CancelToken.source();
                          promiserDropZone.setPromise(
                            apiFetch(
                              `/api/v2/media/presigned-post-public-asset`,
                              {
                                token: session.token,
                                method: 'POST',
                                body: JSON.stringify({
                                  fileType: acceptedFiles[0].type,
                                  fileName: acceptedFiles[0].name,
                                }),
                              }
                            )
                              .then(
                                ({ signedUrl, key }) =>
                                  acceptedFiles != null &&
                                  axios
                                    .put(signedUrl, acceptedFiles[0], {
                                      onUploadProgress: (progressEvent) => {
                                        setUploadProgress(
                                          Math.round(
                                            (progressEvent.loaded * 100) /
                                              progressEvent.total
                                          )
                                        );
                                      },
                                      cancelToken: axiosCancelSource.token,
                                      headers: {
                                        'Content-Type': acceptedFiles[0].type,
                                      },
                                    })
                                    .then(() =>
                                      apiFetch(
                                        `/api/v2/media/get-public-asset-url/${encodeURIComponent(
                                          key
                                        )}`,
                                        {
                                          token: session.token,
                                        }
                                      )
                                    )
                              )
                              .then(({ url }) => {
                                setFieldValue('pdfUrl', url);
                                setFieldValue(
                                  'pdfFileName',
                                  acceptedFiles[0].name
                                );

                                history.replace(
                                  locationForModal({
                                    location,
                                    modal: {
                                      ...modalParams,
                                      pdfUrl: url,
                                      pdfFileName: acceptedFiles[0].name,
                                    },
                                  })
                                );
                              })
                          );
                        }}
                      >
                        {({ isDragReject, rejectedFiles }) => (
                          <div
                            className="card-body text-center"
                            style={{ marginTop: 30 }}
                          >
                            {uploadProgress == null ? (
                              <div
                                className={
                                  isDragReject === true ||
                                  rejectedFiles.length > 0
                                    ? 'text-danger'
                                    : ''
                                }
                              >
                                <p>
                                  Drag and drop your PDF here, <br />
                                  <span style={{ textDecoration: 'underline' }}>
                                    or browse
                                  </span>
                                </p>
                                {(isDragReject === true ||
                                  rejectedFiles.length > 0) && (
                                  <p>Only PDF files are supported.</p>
                                )}
                              </div>
                            ) : (
                              <span className="text-secondary">
                                Progress: {uploadProgress}%
                              </span>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-dark"
                  disabled={isValid === false}
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};

export const EnterpriseContractProductBasedDiscountModal = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: {
  partnerId;
  currency;
  session;
  location;
  history;
  onDismiss;
  onContinue;
  modalParams;
}) => {
  const [uploadProgress, setUploadProgress] = React.useState<
    number | undefined
  >(undefined);

  const promiserDropZone = usePromise();

  function onSubmit(values) {
    onContinue();
  }

  const partnerSubscriptionsQuery = useQuery<{
    partnerById: {
      availableSubscriptions: {
        edges: {
          id: string;
          slug: string;
          description: string;
          displayName: string;
          tier: string;
          price: number;
        }[];
      };
    };
  }>(
    gql`
      query partnerSubscriptionsQuery($partnerId: UUID!) {
        partnerById(uid: $partnerId) {
          availableSubscriptions {
            edges {
              id
              slug
              description
              displayName
              tier
              price
            }
          }
        }
      }
    `,
    {
      variables: {
        partnerId,
      },
      skip: partnerId == null,
    }
  );

  const subscriptionPlans =
    partnerSubscriptionsQuery?.data?.partnerById?.availableSubscriptions?.edges;

  const sortedPlans =
    subscriptionPlans != null
      ? [...subscriptionPlans]?.sort((a, b) => (a.price > b.price ? -1 : 1))
      : null;

  const initialValues: DiscountFormValues = {
    commitmentInUSD:
      modalParams.commitmentInUSD != null
        ? parseFloat(modalParams.commitmentInUSD)
        : '',
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <h4 className="mb-0 ml-4 mt-4">Create a contract</h4>

        <Formik
          initialValues={initialValues}
          validate={validateValuesProductBasedDiscountModal}
          onSubmit={onSubmit}
        >
          {({
            values,
            touched,
            errors,
            isValid,
            handleChange,
            setFieldValue,
          }) => (
            <Form
              className="card-body d-flex flex-column"
              style={{ width: '1000px' }}
            >
              <div className="flex-1" style={{ position: 'relative' }}>
                <div className="row">
                  <div className="form-group col-12">
                    <label
                      className={
                        touched.type && errors.type
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Type
                    </label>

                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-MSA-SOW"
                        className="form-check-input"
                        value="MSA_SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="type-MSA-SOW"
                      >
                        Master Service Agreement (MSA) and Statement of Work
                        (SOW)
                      </label>
                    </div>
                    <div className="form-check">
                      <Field
                        name="type"
                        type="radio"
                        id="type-SOW"
                        className="form-check-input"
                        value="SOW"
                        onChange={(e) => {
                          handleChange(e);
                          history.replace(
                            locationForModal({
                              location,
                              modal: {
                                ...modalParams,
                                type: e.target.value,
                              },
                            })
                          );
                        }}
                      />
                      <label className="form-check-label" htmlFor="type-SOW">
                        Statement of Work (SOW)
                      </label>
                    </div>

                    <ErrorMessage name="type">
                      {(msg) => (
                        <p className="form-group mb-0 text-danger">{msg}</p>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.commitmentInUSD && errors.commitmentInUSD
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Commitment in USD
                    </label>

                    <Field
                      name="commitmentInUSD"
                      type="number"
                      className={`form-control ${
                        touched.commitmentInUSD && errors.commitmentInUSD
                          ? 'border-danger'
                          : ''
                      } tw-w-[300px]`}
                      placeholder="e.g. 100,000 USD"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              commitmentInUSD: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="commitmentInUSD">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.startAt && errors.startAt
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Execution date
                    </label>

                    <Field
                      name="startAt"
                      type="date"
                      placeholder="e.g. 2021-12-31"
                      className={`form-control ${
                        touched.startAt && errors.startAt ? 'border-danger' : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              startAt: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="startAt">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.durationInMonths && errors.durationInMonths
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Term
                    </label>

                    <Field
                      name="durationInMonths"
                      as="select"
                      className={`form-control ${
                        touched.durationInMonths && errors.durationInMonths
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              durationInMonths: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a term
                      </option>
                      {CONTRACT_DURATION.map((duration) => (
                        <option key={duration.value} value={duration.value}>
                          {duration.text}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="durationInMonths">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col">
                    <label
                      className={
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Plan
                    </label>

                    <Field
                      name="subscriptionPlan"
                      as="select"
                      className={`form-control ${
                        touched.subscriptionPlan && errors.subscriptionPlan
                          ? 'border-danger'
                          : ''
                      }`}
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              subscriptionPlan: e.target.value,
                            },
                          })
                        );
                      }}
                    >
                      <option key="default" value="">
                        Select a plan
                      </option>
                      {sortedPlans?.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.displayName} -{' '}
                          {formatMoneyCents(plan.price, { currency })}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="subscriptionPlan">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>

                  <div className="form-group col">
                    <label
                      className={
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'text-danger'
                          : 'text-secondary'
                      }
                    >
                      Referral commissions (%) (Optional)
                    </label>

                    <Field
                      name="referralCommissionsPercentage"
                      type="number"
                      className={`form-control ${
                        touched.referralCommissionsPercentage &&
                        errors.referralCommissionsPercentage
                          ? 'border-danger'
                          : ''
                      }`}
                      placeholder="e.g. 10%"
                      onChange={(e) => {
                        handleChange(e);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              referralCommissionsPercentage: e.target.value,
                            },
                          })
                        );
                      }}
                    />
                    <ErrorMessage name="referralCommissionsPercentage">
                      {(msg) => <p className="text-danger mt-2 mb-0">{msg}</p>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label className="text-secondary">PDF:</label>

                    {modalParams.pdfFileName != null &&
                    modalParams.pdfUrl != null ? (
                      <div
                        className="form-control"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor: '#F6F9FF',
                          border: '1px solid #C1C8D4',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div className="d-flex text-center">
                          <img
                            src={pdfIcon}
                            alt="pdf"
                            style={{
                              width: 35,
                            }}
                          />

                          <div className="d-flex" style={{ width: 250 }}>
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                display: 'inline-block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                paddingRight: '0.5em',
                              }}
                            >
                              {modalParams.pdfFileName}
                            </span>
                          </div>
                        </div>

                        <button
                          style={{
                            backgroundColor: 'transparent',
                            borderWidth: 0,
                            paddingTop: 4,
                          }}
                          onClick={(event) => {
                            setUploadProgress(undefined);
                            history.replace(
                              locationForModal({
                                location,
                                modal: {
                                  ...modalParams,
                                  pdfUrl: undefined,
                                  pdfFileName: undefined,
                                },
                              })
                            );
                          }}
                          className="d-flex text-center align-center"
                        >
                          <i
                            className="fa fa-times text-muted fa-lg"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    ) : (
                      <Dropzone
                        accept="application/pdf"
                        style={{
                          border: '1px dashed #C1C8D4',
                          borderRadius: '4px',
                          height: 160,
                        }}
                        multiple={false}
                        disabled={promiserDropZone.isPending}
                        onDrop={(acceptedFiles) => {
                          if (acceptedFiles.length === 0) return;

                          const axiosCancelSource = axios.CancelToken.source();
                          promiserDropZone.setPromise(
                            apiFetch(
                              `/api/v2/media/presigned-post-public-asset`,
                              {
                                token: session.token,
                                method: 'POST',
                                body: JSON.stringify({
                                  fileType: acceptedFiles[0].type,
                                  fileName: acceptedFiles[0].name,
                                }),
                              }
                            )
                              .then(
                                ({ signedUrl, key }) =>
                                  acceptedFiles != null &&
                                  axios
                                    .put(signedUrl, acceptedFiles[0], {
                                      onUploadProgress: (progressEvent) => {
                                        setUploadProgress(
                                          Math.round(
                                            (progressEvent.loaded * 100) /
                                              progressEvent.total
                                          )
                                        );
                                      },
                                      cancelToken: axiosCancelSource.token,
                                      headers: {
                                        'Content-Type': acceptedFiles[0].type,
                                      },
                                    })
                                    .then(() =>
                                      apiFetch(
                                        `/api/v2/media/get-public-asset-url/${encodeURIComponent(
                                          key
                                        )}`,
                                        {
                                          token: session.token,
                                        }
                                      )
                                    )
                              )
                              .then(({ url }) => {
                                setFieldValue('pdfUrl', url);
                                setFieldValue(
                                  'pdfFileName',
                                  acceptedFiles[0].name
                                );

                                history.replace(
                                  locationForModal({
                                    location,
                                    modal: {
                                      ...modalParams,
                                      pdfUrl: url,
                                      pdfFileName: acceptedFiles[0].name,
                                    },
                                  })
                                );
                              })
                          );
                        }}
                      >
                        {({ isDragReject, rejectedFiles }) => (
                          <div
                            className="card-body text-center"
                            style={{ marginTop: 30 }}
                          >
                            {uploadProgress == null ? (
                              <div
                                className={
                                  isDragReject === true ||
                                  rejectedFiles.length > 0
                                    ? 'text-danger'
                                    : ''
                                }
                              >
                                <p>
                                  Drag and drop your PDF here, <br />
                                  <span style={{ textDecoration: 'underline' }}>
                                    or browse
                                  </span>
                                </p>
                                {(isDragReject === true ||
                                  rejectedFiles.length > 0) && (
                                  <p>Only PDF files are supported.</p>
                                )}
                              </div>
                            ) : (
                              <span className="text-secondary">
                                Progress: {uploadProgress}%
                              </span>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-dark"
                  disabled={isValid === false}
                >
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};

function SelectDiscountCard({
  label,
  fieldName,
  onChange,
  children,
}: {
  label: string;
  fieldName: string;
  onChange: Function;
  children: React.ReactNode;
}) {
  return (
    <div className="tw-justify-between tw-bg-snappr-gray-1 tw-h-14 tw-flex tw-items-center tw-px-4 tw-py-3 tw-rounded-[1px] tw-border tw tw-border-solid tw-border-snappr-gray-2">
      <div className=" tw-pl-0">
        <label
          className={` tw-mb-0 tw-text-snappr-primary tw-font-bold tw-text-lg 
       'tw-text-snappr-primary'
      `}
        >
          {label}
        </label>
      </div>

      <div className="form-group tw-mb-0 tw-px-0 tw-w-32  ">
        <Field
          name={fieldName}
          as="select"
          className={`form-control tw-h-7 tw-p-0 tw-text-base tw-text-snappr-gray-5 `}
          onChange={onChange}
        >
          <option value="">Select</option>
          {children}
        </Field>
      </div>
    </div>
  );
}

export const EnterpriseContractDiscountSelectionModal = ({
  partnerId,
  currency,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: {
  partnerId;
  currency;
  session;
  location;
  history;
  onDismiss;
  onContinue;
  modalParams;
}) => {
  function onSubmit(values) {
    onContinue();
  }

  const discountOptions = [
    { value: 0 },
    { value: 2_00 },
    { value: 2_50 },
    { value: 3_00 },
    { value: 5_00 },
    { value: 10_00 },
  ]; // TODO: bring these values from the backend

  const initialValues = {
    capture: modalParams.capture != null ? parseInt(modalParams.capture) : '',
    aiMagic: modalParams.aiMagic != null ? parseInt(modalParams.aiMagic) : '',
    editingJobs:
      modalParams.editingJobs != null ? parseInt(modalParams.editingJobs) : '',
    planDiscount:
      modalParams.planDiscount != null
        ? parseInt(modalParams.planDiscount)
        : '',
  };

  return (
    <Modal onDismiss={onDismiss} style={{ width: '700px' }}>
      <div className="card my-4">
        <h4 className="mb-0 ml-4 mt-4 tw-text-snappr-gray-5 tw-font-bold tw-text-3xl">
          Select product discounts
        </h4>

        <span className="ml-4 tw-mt-6 tw-text-snappr-gray-5">
          Add a general discount that will apply to an entire vertical
        </span>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validateDiscountValues}
        >
          {({
            values,

            isValid,
            handleChange,
          }) => (
            <Form
              className="card-body d-flex flex-column"
              style={{ width: '1000px' }}
            >
              <div className="flex-1" style={{ position: 'relative' }}>
                <div className="tw-justify-between tw-flex tw-items-center tw-px-4 tw-py-3 tw-text-snappr-gray-4 ">
                  <div className=" tw-pl-0 tw-font-bold">
                    <label>Concept</label>
                  </div>

                  <div className="form-group tw-mb-0 tw-px-0 tw-w-32 tw-font-bold ">
                    <label>Discount</label>
                  </div>
                </div>

                <div
                  className="tw-flex tw-flex-col tw-gap-3 "
                  style={{ paddingBottom: '24px' }}
                >
                  <SelectDiscountCard
                    label="Capture"
                    fieldName="capture"
                    onChange={(e) => {
                      handleChange(e);
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            capture: e.target.value,
                          },
                        })
                      );
                    }}
                  >
                    {discountOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value / 100}%
                      </option>
                    ))}
                  </SelectDiscountCard>

                  <SelectDiscountCard
                    label="AI / Magic"
                    fieldName="aiMagic"
                    onChange={(e) => {
                      handleChange(e);
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            aiMagic: e.target.value,
                          },
                        })
                      );
                    }}
                  >
                    {discountOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value / 100}%
                      </option>
                    ))}
                  </SelectDiscountCard>

                  <SelectDiscountCard
                    label="Editing jobs"
                    fieldName="editingJobs"
                    onChange={(e) => {
                      handleChange(e);
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            editingJobs: e.target.value,
                          },
                        })
                      );
                    }}
                  >
                    {discountOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value / 100}%
                      </option>
                    ))}
                  </SelectDiscountCard>

                  <SelectDiscountCard
                    label="Plan discount"
                    fieldName="planDiscount"
                    onChange={(e) => {
                      handleChange(e);
                      history.replace(
                        locationForModal({
                          location,
                          modal: {
                            ...modalParams,
                            planDiscount: e.target.value,
                          },
                        })
                      );
                    }}
                  >
                    {discountOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.value / 100}%
                      </option>
                    ))}
                  </SelectDiscountCard>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3">
                <button
                  type="submit"
                  className="btn btn-block btn-dark"
                  disabled={isValid === false}
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        label.text-secondary,
        label.text-danger {
          font-size: 0.8rem;
        }

        p.text-danger {
          font-size: 0.8rem;
        }
      `}</style>
    </Modal>
  );
};
