import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  Link,
  RouteComponentProps,
} from 'react-router-dom';
import qs from 'query-string';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import Image from 'next/image';

import BaseLayout from '../../components/BaseLayout';
import ContentWrapper from '../../components/ContentWrapper';
import { SearchField } from '../partners/components';
import { ensureString } from '../../utils/strings';
import { gql, useMutation, useQuery } from '../../components/ApolloClient';
import { TdLink } from '../../components';
import { locationForModal, ModalRoute } from '../../components/ModalRoute';
import { Modal } from '../../components/Modal';
import { formatCredits } from '../../utils';

const basePath = 'ai-food-enterprise-jobs';

const TabCode = {
  AI_FOOD_ENTERPRISE_JOBS_LIST: 'AI_FOOD_ENTERPRISE_JOBS_LIST',
};

const MODAL_NAME_REFUND_PARTNER_RESTAURANT_ITEM =
  'modal-name-refund-partner-restaurant-item';

function RefundGalleryModal({
  restaurantId,
  partnerId,
  galleryImageIdsSelected,
  onDismiss,
  refetch,
}: {
  restaurantId: string | undefined;
  partnerId: string | undefined;
  galleryImageIdsSelected: string[];
  onDismiss: () => void;
  refetch: () => void;
}) {
  const [
    aiFoodPartnerRestaurantItemRefundValidate,
    aiFoodPartnerRestaurantItemRefundValidateMutation,
  ] = useMutation<
    {
      validate: {
        token?: string;
        totalItems?: number;
        price?: number;

        partner?: {
          uid: string;

          name?: string;
        };
      };
    },
    {
      partnerId: string;
      restaurantId: string;
      galleryImageIdsSelected: string[];
    }
  >(
    gql`
      mutation AIFoodPartnerRestaurantImageGroupRefundValidateMutation(
        $restaurantId: ID!
        $partnerId: ID!
        $galleryImageIdsSelected: [ID]!
      ) {
        validate: aiFoodPartnerRestaurantImageGroupRefundValidate(
          input: {
            partnerId: $partnerId
            restaurantId: $restaurantId
            galleryImageIdsSelected: $galleryImageIdsSelected
          }
        ) {
          token

          totalItems
          price

          partner {
            uid

            name
          }
        }
      }
    `
  );

  React.useEffect(() => {
    if (partnerId == null || restaurantId == null) return;

    aiFoodPartnerRestaurantItemRefundValidate({
      variables: {
        partnerId,
        restaurantId,
        galleryImageIdsSelected,
      },
    });
  }, [
    aiFoodPartnerRestaurantItemRefundValidate,
    galleryImageIdsSelected,
    partnerId,
    restaurantId,
  ]);

  const validationData =
    aiFoodPartnerRestaurantItemRefundValidateMutation.data?.validate;

  const [
    aiFoodPartnerRestaurantItemRefundCommit,
    aiFoodPartnerRestaurantItemRefundCommitMutation,
  ] = useMutation<
    {
      commit: {
        groupId: string;
      };
    },
    {
      partnerId: string;
      restaurantId: string;
      galleryImageIdsSelected: string[];
      token: string;
    }
  >(
    gql`
      mutation AIFoodPartnerRestaurantImageGroupRefundCommitMutation(
        $restaurantId: ID!
        $partnerId: ID!
        $galleryImageIdsSelected: [ID]!
        $token: String!
      ) {
        commit: aiFoodPartnerRestaurantImageGroupRefundCommit(
          input: {
            partnerId: $partnerId
            restaurantId: $restaurantId
            galleryImageIdsSelected: $galleryImageIdsSelected
            token: $token
          }
        ) {
          groupId
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div className="card-body d-flex flex-column" style={{ minWidth: 500 }}>
          <h3>
            Refund {validationData?.totalItems} images to{' '}
            {validationData?.partner?.name}
          </h3>

          <p>
            Please confirm that you want to refund {validationData?.totalItems}{' '}
            images for the total of{' '}
            <span className="tw-font-bold">
              {validationData?.price != null
                ? formatCredits(validationData?.price)
                : 0}{' '}
              credits
            </span>
            .
            <br />
            <br />
            <span className="tw-text-[#E11F59]">
              This action cannot be undone.
            </span>
          </p>
          <div className="tw-flex tw-gap-4">
            <button
              type="button"
              onClick={onDismiss}
              className="tw-w-full tw-px-4 tw-h-10 tw-text-center tw-border tw-border-[#C1C8D4] tw-border-solid tw-bg-white tw-rounded disabled:tw-bg-snappr-gray-3"
            >
              No, cancel
            </button>

            <button
              type="button"
              className="tw-w-full tw-px-4 tw-h-10 tw-text-center tw-text-white tw-border tw-border-[#141B24] tw-border-solid tw-bg-[#141B24] tw-rounded disabled:tw-bg-snappr-gray-3"
              onClick={() => {
                if (
                  partnerId == null ||
                  restaurantId == null ||
                  validationData?.token == null
                )
                  return;

                aiFoodPartnerRestaurantItemRefundCommit({
                  variables: {
                    partnerId,
                    restaurantId,
                    galleryImageIdsSelected,
                    token: validationData.token,
                  },
                }).then((res) => {
                  if (res.data?.commit.groupId != null) {
                    refetch();
                    onDismiss();
                  }
                });
              }}
              disabled={
                partnerId == null ||
                restaurantId == null ||
                validationData?.token == null ||
                aiFoodPartnerRestaurantItemRefundValidateMutation.loading ||
                aiFoodPartnerRestaurantItemRefundCommitMutation.loading
              }
            >
              Yes, refund {validationData?.totalItems} images
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

function AIFoodEnterpriseRestaurantGalleryRoute({
  match,
  history,
  location,
}: {
  match: { params: { restaurantId: string } };
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}) {
  const {
    params: { restaurantId },
  } = match;

  const search = React.useMemo(
    () => ensureString(qs.parse(location.search)['q'] ?? null),
    [location]
  );

  const [galleryIdsSelected, setGalleryIdsSelected] = React.useState<string[]>(
    []
  );

  const aiFoodPartnerRestaurantQuery = useQuery<{
    aiFoodPartnerRestaurantById?: {
      id: string;

      name?: string;

      partner?: {
        uid: string;

        name?: string;
      };

      aiFoodPartnerRestaurantItemGroupList?: {
        edges?: {
          id: string;

          aiFoodPartnerRestaurantItemList?: {
            edges?: {
              id: string;

              name?: string;
              order?: string;
              refundGroupId?: string;

              media?: {
                id: string;

                galleryThumb?: {
                  url?: string;
                };
              };
            }[];
          };
        }[];
      };
    };
  }>(
    gql`
      query aiFoodPartnerRestaurantQueryFromAdmin($id: ID!, $search: String) {
        aiFoodPartnerRestaurantById(id: $id) {
          id

          name

          partner {
            uid

            name
          }

          aiFoodPartnerRestaurantItemGroupList {
            edges {
              id

              aiFoodPartnerRestaurantItemList(
                includeRefunded: true
                search: $search
              ) {
                edges {
                  id

                  name
                  order
                  refundGroupId

                  media {
                    id

                    galleryThumb {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        id: restaurantId,
        search,
      },
      skip: restaurantId == null,
    }
  );

  const restaurantData =
    aiFoodPartnerRestaurantQuery?.data?.aiFoodPartnerRestaurantById;

  const itemList = restaurantData?.aiFoodPartnerRestaurantItemGroupList?.edges?.flatMap(
    (group) => {
      return group.aiFoodPartnerRestaurantItemList?.edges;
    }
  );

  const partnerId = restaurantData?.partner?.uid;

  const isAllSelected = React.useMemo(() => {
    return (itemList ?? []).every(
      (item) => item?.id != null && galleryIdsSelected.includes(item.id)
    );
  }, [galleryIdsSelected, itemList]);

  const onSelectAll = React.useCallback(() => {
    setGalleryIdsSelected(
      isAllSelected
        ? []
        : itemList?.map((item) => item?.id).filter((id) => id != null) ?? []
    );
  }, [isAllSelected, itemList]);

  return (
    <div className="tw-mb-24">
      <h2>Restaurants / {restaurantData?.name}</h2>

      <div className="tw-w-full tw-flex tw-items-center tw-my-4 tw-justify-between">
        <SearchField
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          defaultValue={qs.parse(location.search).q || ''}
        />

        <div className="tw-flex tw-gap-4">
          <button
            type="button"
            onClick={onSelectAll}
            className="tw-px-4 tw-h-10 tw-text-center tw-border tw-border-[#C1C8D4] tw-border-solid tw-bg-white tw-rounded disabled:tw-opacity-50"
          >
            {isAllSelected ? 'Deselect all' : 'Select all'}
          </button>

          <Link
            className={
              'btn tw-px-4 tw-h-10 tw-text-white tw-border tw-border-[#141B24] tw-border-solid tw-bg-[#141B24] tw-rounded' +
              (galleryIdsSelected.length <= 0
                ? ' tw-pointer-events-none tw-opacity-50 tw-cursor-default'
                : '')
            }
            to={locationForModal({
              location,
              modal: { modalName: MODAL_NAME_REFUND_PARTNER_RESTAURANT_ITEM },
            })}
          >
            Refund selected
          </Link>
        </div>
      </div>

      <div className="tw-grid tw-grid-cols-[repeat(auto-fill,minmax(226px,1fr))] tw-gap-4 tw-w-full">
        {itemList?.map(
          (item) =>
            item != null && (
              <div
                className="tw-w-full tw-aspect-square tw-bg-gray-50 tw-relative"
                key={item.id}
              >
                <div className="tw-ml-auto tw-flex tw-bg-blue-500 tw-text-white tw-rounded tw-px-2">
                  {item.media?.galleryThumb?.url != null && (
                    <Image
                      className="tw-w-full tw-aspect-square tw-bg-gray-50"
                      src={item.media.galleryThumb.url}
                      alt="dish"
                      unoptimized
                      fill
                    />
                  )}
                </div>

                {item.refundGroupId != null && (
                  <span className="tw-absolute tw-bottom-4 tw-right-4 tw-px-1 tw-font-bold tw-text-[10px] tw-text-white tw-bg-[#E11F59]">
                    REFUNDED
                  </span>
                )}

                <input
                  className="tw-absolute tw-top-4 tw-right-4 tw-w-6 tw-h-6 tw-bg-white tw-cursor-pointer"
                  type="checkbox"
                  checked={galleryIdsSelected.includes(item.id)}
                  onChange={() =>
                    setGalleryIdsSelected((prev) =>
                      prev.includes(item.id)
                        ? prev.filter((id) => id !== item.id)
                        : [...prev, item.id]
                    )
                  }
                />
              </div>
            )
        )}
      </div>

      <ModalRoute
        modalName={MODAL_NAME_REFUND_PARTNER_RESTAURANT_ITEM}
        render={() => (
          <RefundGalleryModal
            restaurantId={restaurantId}
            partnerId={partnerId!}
            galleryImageIdsSelected={galleryIdsSelected}
            refetch={aiFoodPartnerRestaurantQuery.refetch}
            onDismiss={() =>
              Promise.resolve().then(() =>
                history.push(locationForModal({ location, modal: undefined }))
              )
            }
          />
        )}
      />
    </div>
  );
}

function DataRowsChunk({
  cursor,
  location,
  onMore,
}: {
  cursor?: string;
  location: RouteComponentProps['location'];
  onMore?: ({ nextCursor }: { nextCursor: string }) => void;
}) {
  const query = React.useMemo(
    () => ensureString(qs.parse(location.search)['q'] ?? null),
    [location]
  );

  const aiRestaurantListQuery = useQuery<{
    aiFoodPartnerRestaurantList: {
      cursor: string;
      first: number;
      edges?: {
        id: string;

        name?: string;

        partner?: {
          uid: string;

          name?: string;
        };
      }[];
    };
  }>(
    gql`
      query aiFoodRestaurantListFromAdmin(
        $cursor: String
        $first: Int
        $query: String
      ) {
        aiFoodPartnerRestaurantList(
          cursor: $cursor
          first: $first
          query: $query
        ) {
          cursor
          edges {
            id
            name

            partner {
              uid

              name
            }
          }
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
        query,
      },
      fetchPolicy: 'network-only',
    }
  );

  const restaurantList =
    aiRestaurantListQuery.data?.aiFoodPartnerRestaurantList.edges;

  const nextCursor =
    aiRestaurantListQuery.data?.aiFoodPartnerRestaurantList?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {restaurantList == null && aiRestaurantListQuery.loading
        ? Array.from(Array(4)).map((_, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : restaurantList?.map((restaurant) => (
            <tr key={restaurant.id}>
              <TdLink
                title={restaurant.name}
                to={`${basePath}/${restaurant.id}`}
                className="text-truncate"
              >
                {restaurant.name}
              </TdLink>
              <TdLink
                title={restaurant.id}
                to={`${basePath}/${restaurant.id}`}
                className="text-truncate"
              >
                {restaurant.id}
              </TdLink>
              <TdLink
                title={restaurant.partner?.uid}
                to={`${basePath}/${restaurant.id}`}
                className="text-truncate"
              >
                {restaurant.partner?.name}
              </TdLink>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

function AiProductJobsList({
  location,
  history,
  cursors,
  setCursors,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  cursors: string[];
  setCursors: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  return (
    <div className="table-responsive tw-relative tw-p-4">
      <div className="tw-flex tw-justify-start tw-items-center">
        <SearchField
          style={{ margin: 8 }}
          onSearch={({ q }) =>
            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                q: q || undefined,
              }),
            })
          }
          defaultValue={qs.parse(location.search).q || ''}
        />
      </div>

      <div style={{ margin: 8, display: 'flex', flexWrap: 'wrap' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Name</th>
              <th className="text-muted text-truncate">ID</th>
              <th className="text-muted text-truncate">Partner</th>
            </tr>
          </thead>

          <tbody>
            {[null, ...cursors].map((cursor, index) => (
              <DataRowsChunk
                location={location}
                key={cursor ?? '0'}
                cursor={cursor ?? '0'}
                onMore={
                  index === cursors.length + 1 - 1
                    ? ({ nextCursor }) =>
                        setCursors((state) =>
                          state.includes(nextCursor)
                            ? state
                            : [...state, nextCursor]
                        )
                    : undefined
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function AIFoodEnterpriseRestaurantListRoute({
  location,
  history,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}) {
  const [cursors, setCursors] = React.useState<string[]>([]);

  const selectedTab =
    qs.parse(location.search).selectedTab ??
    TabCode.AI_FOOD_ENTERPRISE_JOBS_LIST;

  return (
    <>
      <h2>Restaurants</h2>

      <ContentWrapper>
        <div className="table-responsive tw-relative">
          <div className="d-flex flex-column" style={{ minHeight: 400 }}>
            <div className="card flex-1">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <li className="nav-item">
                    <Link
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className={
                        'btn btn-link nav-link' +
                        (selectedTab !== TabCode.AI_FOOD_ENTERPRISE_JOBS_LIST
                          ? ''
                          : ' active')
                      }
                      to={{
                        ...location,
                        search: qs.stringify({
                          ...qs.parse(location.search),
                          selectedTab: TabCode.AI_FOOD_ENTERPRISE_JOBS_LIST,
                          q: undefined,
                        }),
                      }}
                    >
                      <strong className="mb-0">Restaurants list</strong>
                    </Link>
                  </li>
                </ul>
              </div>

              {TabCode.AI_FOOD_ENTERPRISE_JOBS_LIST === selectedTab ? (
                <AiProductJobsList
                  cursors={cursors}
                  setCursors={setCursors}
                  location={location}
                  history={history}
                />
              ) : (
                <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
                  <h3>{selectedTab}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </>
  );
}

export function AIFoodEnterpriseRoute({
  session,
  match,
  match: { path: basePath },
  history,
  location,
}) {
  return (
    <Switch>
      <Route
        render={() => (
          <BaseLayout session={session} match={match}>
            <Switch>
              <Route
                exact
                path={`${basePath}`}
                render={(props) => {
                  return (
                    <AIFoodEnterpriseRestaurantListRoute
                      {...props}
                      location={location}
                      history={history}
                    />
                  );
                }}
              />

              <Route
                exact
                path={`${basePath}/:restaurantId`}
                render={(props) => {
                  return (
                    <AIFoodEnterpriseRestaurantGalleryRoute
                      {...props}
                      location={location}
                      session={session}
                      history={history}
                    />
                  );
                }}
              />

              <Route
                render={({ location }) => (
                  <Redirect to={{ ...location, pathname: basePath }} />
                )}
              />
            </Switch>
          </BaseLayout>
        )}
      />
    </Switch>
  );
}
