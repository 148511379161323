import React from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps, Link } from 'react-router-dom';
import Component2 from '@reach/component-component';

import { APARSNIP_URL } from '../../../config';
import {
  ProgressIndicator,
  useUploadManager,
} from '../../../components/useUploadManager';
import { apiFetch, checkIfCanAccess, formatMoneyCents } from '../../../utils';
import { gql, useMutation, useQuery } from '../../../components/ApolloClient';
import { COLOR_PALLETTE } from '../../../constants';
import { LoadingSpinnerCentered } from '../../../components/LoadingSpinner';
import { locationForModal, ModalRoute } from '../../../components/ModalRoute';
import { Modal } from '../../../components/Modal';

import downloadImage from '../../../img/download-image.svg';

const ECOMMERCE_JOB_REFUND_MODAL = 'ecommerce_job_refund_modal';

const EcommerceJobRefundModal = ({
  ecommerceJobId,
  onDismiss,
}: {
  ecommerceJobId: string;
  onDismiss: () => any;
}) => {
  const [
    ecommerceJobRefundValidate,
    {
      data: validateRefundData,
      loading: validateRefundLoading,
      error: validateRefundError,
    },
  ] = useMutation<
    {
      ecommerceJobRefundValidate: {
        validationToken: string;
        amountToRefund: number;
        ecommerceJob: {
          id: string;
          externalId?: string;
          name?: string;
          status?: string;
          package?: { type: string };
          partner?: { uid: string; name: string };
        };
      };
    },
    { ecommerceJobId: string }
  >(
    gql`
      mutation ecommerceJobRefundValidateAsAdmin($ecommerceJobId: String!) {
        ecommerceJobRefundValidate(input: { ecommerceJobId: $ecommerceJobId }) {
          amountToRefund
          validationToken
          ecommerceJob {
            id
            externalId
            name
            status
            package {
              type
            }
            partner {
              uid
              name
            }
          }
        }
      }
    `,
    {
      variables: { ecommerceJobId },
    }
  );

  const token = validateRefundData?.ecommerceJobRefundValidate?.validationToken;

  const amountToRefund =
    validateRefundData?.ecommerceJobRefundValidate?.amountToRefund;

  const ecommerceJob =
    validateRefundData?.ecommerceJobRefundValidate?.ecommerceJob;

  const [
    ecommerceJobRefundCommit,
    {
      data: commitRefundData,
      error: commitRefundError,
      loading: commitRefundLoading,
      called: commitRefundCalled,
    },
  ] = useMutation<{
    ecommerceJobRefundCommit: {
      success: boolean;
    };
  }>(
    gql`
      mutation ecommerceJobRefundCommitAsAdmin(
        $ecommerceJobId: String!
        $validationToken: String!
      ) {
        ecommerceJobRefundCommit(
          input: {
            ecommerceJobId: $ecommerceJobId
            validationToken: $validationToken
          }
        ) {
          success
        }
      }
    `
  );

  return (
    <Modal onDismiss={onDismiss}>
      <Component2 didMount={() => ecommerceJobRefundValidate()} />

      <form
        className="card my-4"
        onSubmit={(e) => {
          e.preventDefault();

          const finalConfirmation = window.confirm(
            'Are you sure you want to refund?'
          );

          if (finalConfirmation !== true) return;

          ecommerceJobRefundCommit({
            variables: {
              ecommerceJobId,
              validationToken: token,
            },
          });
        }}
      >
        <div className="card-header">
          <h4 className="mb-0">Refund Ecommerce Job</h4>
        </div>

        <div className="card-body d-flex flex-column">
          {commitRefundLoading && <p>Loading refund...</p>}

          {commitRefundError && (
            <p className="text-danger mt-2 mb-0">{commitRefundError.message}</p>
          )}

          {validateRefundError && (
            <p className="text-danger mt-2 mb-0">
              {validateRefundError.message}
            </p>
          )}

          {validateRefundLoading ? (
            <p
              style={{
                width: '30vw',
                height: '20vh',
                display: 'grid',
                placeContent: 'center',
              }}
            >
              Loading...
            </p>
          ) : (
            validateRefundData != null && (
              <>
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <th>Partner name</th>
                      <td>{ecommerceJob?.partner?.name}</td>
                    </tr>
                    <tr>
                      <th>Job ID</th>
                      <td>
                        <span className="tw-font-mono">{ecommerceJobId}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Job external ID</th>
                      <td>
                        {ecommerceJob?.externalId != null ? (
                          <a
                            href={`${APARSNIP_URL}/ecommerce-scene/${ecommerceJob.externalId}`}
                            target="_blank"
                            rel="noreferrer"
                            className="tw-font-mono"
                          >
                            {ecommerceJob.externalId}
                          </a>
                        ) : (
                          'None'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Job name</th>
                      <td>{ecommerceJob?.name}</td>
                    </tr>
                    <tr>
                      <th>Job type</th>
                      <td>
                        {ecommerceJob?.package?.type.includes(
                          'aiEcommerceVideoProduct'
                        )
                          ? 'Video'
                          : 'Image'}
                      </td>
                    </tr>
                    <tr>
                      <th>Job status</th>
                      <td>{ecommerceJob?.status}</td>
                    </tr>
                    <tr>
                      <th>Amount to refund</th>
                      <td>
                        {formatMoneyCents(amountToRefund, {
                          currency: 'USD',
                        })}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {commitRefundData?.ecommerceJobRefundCommit.success ===
                  true && (
                  <div
                    className="alert alert-success"
                    style={{ marginBottom: '8px' }}
                  >
                    Refund successful.
                  </div>
                )}

                {commitRefundCalled === false && (
                  <div className="tw-w-full tw-text-center">
                    <p className="tw-m-0 tw-text-snappr-red tw-text-3 tw-font-bold">
                      <i aria-hidden="true" className={`fa fa-warning px-1`} />{' '}
                      Please verify everything is correct as this action cannot
                      be undone
                    </p>
                  </div>
                )}
              </>
            )
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={onDismiss}
          >
            Close
          </button>

          {commitRefundData?.ecommerceJobRefundCommit.success !== true && (
            <button
              type="submit"
              className="btn btn-danger"
              disabled={
                validateRefundLoading === true || validateRefundError != null
              }
            >
              Refund{' '}
              {amountToRefund != null
                ? formatMoneyCents(amountToRefund, {
                    currency: 'USD',
                  })
                : ''}
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export const AiEcommerceVideoDetails = ({
  match: {
    params: { jobId },
  },
  session,
  session: { token },
  history,
  location,
}: {
  match;
  session: {
    token: string;
    uid: string;
    user?: { IAM?: string[] };
  };
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
} & RouteComponentProps<{ jobId?: string }>) => {
  const ecommerceJobData = useQuery<
    {
      ecommerceJobById: {
        id: string;
        status?: string;
        name?: string;
        package: {
          id: string;
          type: string;
        };
        additionalNotes: string;
        durationInSeconds?: number;
        aspectRatio?: string;
        videoMedia?: {
          id: string;
          downloadUrl?: string;
        };
        referenceMedia?: {
          id: string;
          small?: { url: string };
          medium?: { url: string };
          large?: { url: string };
          downloadUrl?: string;
        };
      };
    },
    {
      id: string;
    }
  >(
    gql`
      query ecommerceJobQueryAsAdmin($id: ID!) {
        ecommerceJobById(id: $id) {
          id
          status
          name
          package {
            id
            type
          }
          additionalNotes
          id
          durationInSeconds
          aspectRatio
          referenceMedia {
            id
            small {
              url
            }
            medium {
              url
            }
            large {
              url
            }
            downloadUrl
          }
          videoMedia {
            id
            downloadUrl
          }
        }
      }
    `,
    {
      variables: { id: jobId },
      skip: jobId == null,
    }
  );

  const ecommerceJobDetails = ecommerceJobData.data?.ecommerceJobById;

  const [linkVideoToEcommerceJob] = useMutation<
    {
      ecommerceMediaLinkToSceneAsAdmin: { success: boolean };
    },
    {
      ecommerceProductSceneId: string;
      mediaId: string;
    }
  >(
    gql`
      mutation ecommerceVideoUploadAsAdmin(
        $ecommerceProductSceneId: ID!
        $mediaId: ID!
      ) {
        ecommerceMediaLinkToSceneAsAdmin(
          input: {
            ecommerceProductSceneId: $ecommerceProductSceneId
            mediaId: $mediaId
          }
        ) {
          success
        }
      }
    `
  );

  const jobIsVideo =
    ecommerceJobDetails?.package.type === 'aiEcommerceVideoProduct';

  const disableVideoUpload =
    jobIsVideo !== true || ecommerceJobDetails?.status === 'shipped';

  const [videoMediaId, setVideoMediaId] = React.useState<string | null>(null);
  const [videoMediaName, setVideoMediaName] = React.useState<string | null>(
    null
  );

  const { addFiles, uploadsList, clearUploads } = useUploadManager({
    token: session.token,
    onUploadReady: ({ fileName, mediaUid, remove, isVideo }) =>
      apiFetch('/api/v2/media/create-from-temp', {
        token,
        method: 'POST',
        body: JSON.stringify({
          tempMediaUid: mediaUid,
          fileName,
          isVideo,
        }),
      }).then((response: { uid: string; file_name: string }) => {
        setVideoMediaId(response.uid);
        setVideoMediaName(response.file_name);
      }),
  });

  const handleSubmit = React.useCallback(async () => {
    if (videoMediaId == null) return;

    await linkVideoToEcommerceJob({
      variables: {
        ecommerceProductSceneId: jobId,
        mediaId: videoMediaId,
      },
    });

    window.alert('Video successfully linked to the ecommerce job');
    ecommerceJobData.refetch();
  }, [videoMediaId, jobId, linkVideoToEcommerceJob, ecommerceJobData]);

  return (
    <div className="tw-flex tw-bg-white tw-p-4 tw-rounded-lg tw-flex-col tw-gap-5">
      <div className="tw-flex tw-justify-between tw-items-center">
        <h1>
          AI ecommerce job
          <i aria-hidden="true" className={`fa fa-film px-2`} />
        </h1>

        {checkIfCanAccess(session, 'aiEcommerceJobRefund') && (
          <Link
            className="btn btn-primary "
            to={locationForModal({
              location,
              modal: {
                modalName: ECOMMERCE_JOB_REFUND_MODAL,
                id: jobId,
              },
            })}
          >
            Refund
          </Link>
        )}
      </div>

      <div className="tw-flex tw-gap-2 tw-w-full tw-justify-between">
        <div
          className="tw-flex tw-flex-col tw-border tw-border-solid tw-border-snappr-gray-2 tw-rounded-sm
         tw-p-2 tw-gap-2 tw-w-full"
        >
          <span className="tw-font-bold tw-text-xl">Video details</span>

          <div className="tw-flex tw-gap-1">
            <span className="tw-font-bold">Status:</span>
            <span className=" tw-text-snappr-gray-5">
              {ecommerceJobDetails?.status ?? 'In progress'}
            </span>
          </div>

          <div className="tw-flex tw-gap-1">
            <span className="tw-font-bold">Name:</span>
            <span className=" tw-text-snappr-gray-5">
              {ecommerceJobDetails?.name}
            </span>
          </div>

          <div className="tw-flex tw-flex-col tw-gap-1">
            <span className="tw-font-bold">Notes on the video:</span>
            <span className=" tw-text-snappr-gray-5 tw-max-w-[1000px] tw-break-words">
              {ecommerceJobDetails?.additionalNotes ?? 'None added'}
            </span>
          </div>

          <div className="tw-flex tw-gap-1">
            <span className="tw-font-bold">Duration in seconds:</span>
            <span className=" tw-text-snappr-gray-5">
              {ecommerceJobDetails?.durationInSeconds}
            </span>
          </div>

          <div className="tw-flex tw-gap-1">
            <span className="tw-font-bold">Aspect ratio:</span>
            <span className=" tw-text-snappr-gray-5">
              {ecommerceJobDetails?.aspectRatio}
            </span>
          </div>
        </div>

        <div
          className="tw-flex tw-flex-col tw-border tw-border-solid tw-border-snappr-gray-2 tw-rounded-sm
         tw-p-2 tw-gap-2"
        >
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-bold tw-text-xl">Reference image</span>

            <a
              download={`${ecommerceJobDetails?.referenceMedia?.id}.jpg`}
              href={ecommerceJobDetails?.referenceMedia?.downloadUrl}
              rel={'noreferrer'}
              title="SampleName"
            >
              <img src={downloadImage} alt="download" />
            </a>
          </div>

          <div className="tw-w-[300px]">
            {ecommerceJobDetails?.referenceMedia?.medium?.url ? (
              <img
                src={ecommerceJobDetails?.referenceMedia?.medium?.url}
                alt="Reference media"
                className="tw-h-auto tw-w-full tw-rounded"
              />
            ) : (
              <div>
                <LoadingSpinnerCentered />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="tw-flex tw-flex-col tw-gap-2">
        <span className="tw-font-bold tw-text-xl">
          Upload the required media for the job
        </span>

        <Dropzone
          style={{
            border: '1px dashed #C1C8D4',
            borderRadius: '4px',
            height: 160,
            backgroundColor: COLOR_PALLETTE.GRAY_2,
            cursor: disableVideoUpload ? 'not-allowed' : 'pointer',
          }}
          multiple={false}
          onDrop={(acceptedFiles) => {
            clearUploads?.();
            setVideoMediaId(null);
            setVideoMediaName(null);
            addFiles({ acceptedFiles });
          }}
          accept={'video/mp4, video/mov, video/avi, video/mkv'}
          disabled={disableVideoUpload}
        >
          <div className="card-body text-center" style={{ marginTop: 30 }}>
            <div>
              <p>
                <i aria-hidden="true" className={`fa fa-film px-2`} />
                Drag and drop your video here, <br />
                <span style={{ textDecoration: 'underline' }}>or browse</span>
              </p>
            </div>
          </div>
        </Dropzone>

        {ecommerceJobDetails?.videoMedia?.id && (
          <div className="tw-flex tw-flex-col tw-gap-2">
            <span className="tw-font-bold tw-text-xl">Video preview</span>
            <video width="500" height="auto" controls>
              <source
                src={ecommerceJobDetails?.videoMedia?.downloadUrl}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        {uploadsList != null &&
          videoMediaId == null &&
          uploadsList.map((file) => (
            <div key={file.clientId}>
              {file.fileName}
              {file.sizeUploaded != null && file.fileSize != null && (
                <div>
                  <ProgressIndicator
                    progress={file.sizeUploaded / file.fileSize}
                  />
                </div>
              )}
            </div>
          ))}

        {videoMediaId != null && (
          <div className="tw-flex tw-gap-1 tw-justify-between tw-bg-snappr-gray-1 tw-text-snappr-primary tw-rounded tw-p-2 tw-border-solid tw-border-snappr-gray-2 tw-border-2">
            <span>{videoMediaName}</span>
            <button
              className="tw-bg-snappr-gray-1 tw-text-snappr-primary tw-border-none tw-py-1 tw-px-2 tw-flex tw-gap-1"
              onClick={() => {
                clearUploads?.();
                setVideoMediaId(null);
                setVideoMediaName(null);
              }}
            >
              Remove
            </button>
          </div>
        )}

        <button
          type="submit"
          className="btn btn-primary btn-block"
          disabled={videoMediaId == null || disableVideoUpload}
          onClick={handleSubmit}
        >
          Submit video
        </button>
      </div>

      <ModalRoute
        modalName={ECOMMERCE_JOB_REFUND_MODAL}
        render={() => (
          <EcommerceJobRefundModal
            ecommerceJobId={jobId}
            onDismiss={() =>
              history.replace(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
    </div>
  );
};
