import { useFetch } from './useFetch';

export function MetabaseEmbed(props: { report: string }) {
  const { result } = useFetch<{
    iframe_url: string;
  }>({
    urlToFetch: `/api/v2/metabase-reports`,
    queryToFetch: {
      report: props.report,
    },
  });

  return result?.iframe_url == null ? null : (
    <iframe
      title="Metabase embed"
      src={result?.iframe_url}
      height={800}
      className="tw-w-full tw-border-none"
      allowTransparency
    />
  );
}
